exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-apps-tsx": () => import("./../../../src/pages/apps.tsx" /* webpackChunkName: "component---src-pages-apps-tsx" */),
  "component---src-pages-foods-tsx": () => import("./../../../src/pages/foods.tsx" /* webpackChunkName: "component---src-pages-foods-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-me-tsx": () => import("./../../../src/pages/me.tsx" /* webpackChunkName: "component---src-pages-me-tsx" */),
  "component---src-pages-writ-tsx": () => import("./../../../src/pages/writ.tsx" /* webpackChunkName: "component---src-pages-writ-tsx" */),
  "component---src-pages-writs-tsx": () => import("./../../../src/pages/writs.tsx" /* webpackChunkName: "component---src-pages-writs-tsx" */)
}

